import { Fancybox } from '@fancyapps/ui';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  const atLeastOneBlock = !!document.querySelector('.medias-carousel-block');
  if (!atLeastOneBlock) {
    // BAIL. No carousel blocks.
    return;
  }

  ///// Init Fancy box only for those carousels.
  Fancybox.bind('.medias-carousel-block [data-fancybox]', {});

  ///// Init Swipers
  new window.VortexSliders('.vertical-size-big .medias-carousel__carousel', {
    centeredSlides: true,
    spaceBetween: 20,
    slidesPerView: 2,
    pagination: {
      el: '.vertical-size-big .medias-carousel__carousel-navigation .swiper-pagination',
      type: 'progressbar',
    },
    navigation: {
      nextEl:
        '.vertical-size-big .medias-carousel__carousel-navigation__arrows .swiper-button-next',
      prevEl:
        '.vertical-size-big .medias-carousel__carousel-navigation__arrows .swiper-button-prev',
    },
  });

  new window.VortexSliders('.vertical-size-small .medias-carousel__carousel', {
    centeredSlides: true,
    spaceBetween: 20,
    slidesPerView: 4,
    pagination: {
      el: '.vertical-size-small .medias-carousel__carousel-navigation .swiper-pagination',
      type: 'progressbar',
    },
    navigation: {
      nextEl:
        '.vertical-size-small .medias-carousel__carousel-navigation__arrows .swiper-button-next',
      prevEl:
        '.vertical-size-small .medias-carousel__carousel-navigation__arrows .swiper-button-prev',
    },
  });
});
